@font-face {
    font-family: 'Marianne';
    src: url('Marianne-Thin.woff2') format('woff2'),
        url('Marianne-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Marianne';
    src: url('Marianne-Light.woff2') format('woff2'),
        url('Marianne-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Marianne';
    src: url('Marianne-Regular.woff2') format('woff2'),
        url('Marianne-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Marianne';
    src: url('Marianne-Medium.woff2') format('woff2'),
        url('Marianne-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Marianne';
    src: url('Marianne-Bold.woff2') format('woff2'),
        url('Marianne-Bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Marianne';
    src: url('Marianne-Bold.woff2') format('woff2'),
        url('Marianne-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Marianne';
    src: url('Marianne-ExtraBold.woff2') format('woff2'),
        url('Marianne-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

/* Italic */
@font-face {
    font-family: 'Marianne';
    src: url('Marianne-ThinItalic.woff2') format('woff2'),
        url('Marianne-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Marianne';
    src: url('Marianne-LightItalic.woff2') format('woff2'),
        url('Marianne-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Marianne';
    src: url('Marianne-RegularItalic.woff2') format('woff2'),
        url('Marianne-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Marianne';
    src: url('Marianne-MediumItalic.woff2') format('woff2'),
        url('Marianne-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Marianne';
    src: url('Marianne-BoldItalic.woff2') format('woff2'),
        url('Marianne-BoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Marianne';
    src: url('Marianne-BoldItalic.woff2') format('woff2'),
        url('Marianne-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Marianne';
    src: url('Marianne-ExtraBoldItalic.woff2') format('woff2'),
        url('Marianne-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}


