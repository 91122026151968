@font-face {
    font-family: 'DINOT';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src:
        local('DINOT'),
        url('./DINOT.woff2') format('woff2'),
        url('./DINOT.woff') format('woff');
}

@font-face {
    font-family: 'DINOT';
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    src:
            local('DINOT Bold'),
            url('./DINOT-Bold.woff2') format('woff2'),
            url('./DINOT-Bold.woff') format('woff');
}

