@font-face {
    font-family: 'Klinic Slab';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src:
            local('Klinic Slab Book'),
            url('./Klinic-Slab-Book.woff2') format('woff2'),
            url('./Klinic-Slab-Book.woff') format('woff');
}

@font-face {
    font-family: 'Klinic Slab';
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    src:
            local('Klinic Slab Bold'),
            url('./Klinic-Slab-Bold.woff2') format('woff2'),
            url('./Klinic-Slab-Bold.woff') format('woff');
}